body {
  font-family: 'Nunito', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.topbar-logo {
  height: 40px;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px); /* Adjust height for topbar */
  background: url('../assets/images/agriculture.jpg') no-repeat center center/cover;
  position: relative;
  overflow: hidden;
}

.login-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Black shade overlay */
  z-index: 1;
}

.login-box {
  display: flex;
  width: 90%;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.9); /* Slightly transparent background */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  animation: fadeIn 1s ease-in-out;
  z-index: 2;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.login-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(34, 139, 34, 0.8); /* Green background with some transparency */
  color: white;
  animation: slideInFromLeft 1s ease-in-out;
}

@keyframes slideInFromLeft {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

.login-welcome {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../assets/images/bgportal.jpg') no-repeat center center/cover;  
  color: rgb(16, 0, 0);
  text-align: center;
  animation: slideInFromRight 1s ease-in-out;
}

@keyframes slideInFromRight {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

.login-input {
  width: 100%;
  margin-bottom: 20px;
}

.login-input input {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background: #fdfcff;
  color: black;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.login-input input:focus {
  background: #e0e0e0;
}

.login-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.login-remember input {
  margin-right: 10px;
}

.forgot-password {
  color: #e91e63;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.login-button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background: #e91e63;
  color: rgb(255, 255, 255);
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.login-button:hover {
  background: #c2185b;
}

.login-welcome h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.login-welcome p {
  font-size: 18px;
  margin-bottom: 20px;
}

.login-image {
  margin-top: 20px;
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
}

.login-image:hover {
  transform: scale(1.05);
}
