/* General Styles */

/* White color only for Our Services heading */
.white-text {
    color: white;
  }
  
  /* Flip card container */
  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 250px; /* Uniform height for all cards */
    perspective: 1000px; /* Perspective for 3D effect */
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s ease; /* Smooth transition */
    transform-style: preserve-3d;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg); /* Rotate on hover */
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.3); /* Elevated shadow on hover */
  }
  
  /* Front and back face */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Hide the back face when not shown */
    backface-visibility: hidden;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .flip-card-back {
    background-color: #03856d;
    color: white;
    transform: rotateY(180deg); /* Start rotated for back */
    padding: 20px;
  }
  
  .flip-card-front {
    background-color: #03856d;
    color: white;
    padding: 20px;
  }
  
  .service-icon {
    margin-bottom: 15px;
  }
  
  /* Custom styling for the product headings */
  .product-heading {
    font-weight: bold;
    color: #ffffff;
    margin-top: 10px;
    text-transform: uppercase;
  }
  