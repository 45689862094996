/* src/components/UserCreationPage.css */
body {
  font-family: 'Nunito', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.topbar-logo {
  height: 40px;
}

.user-creation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px); /* Adjust height for topbar */
  background: url('../assets/images/agriculture.jpg') no-repeat center center/cover;
  position: relative;
  overflow: hidden;
}

.user-creation-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Black shade overlay */
  z-index: 1;
}

.user-creation-box {
  width: 90%;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.9); /* Slightly transparent background */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  z-index: 2;
  overflow-y: auto;
  max-height: 80vh; /* Make the box scrollable */
}

.user-creation-box h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: xx-large;
}

.user-creation-card {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  animation: fadeInUp 0.8s ease-in-out;
}

.user-creation-card h3 {
  margin-bottom: 10px;
  text-align: center;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.user-creation-input {
  width: 100%;
  margin-bottom: 20px;
}

.user-creation-input input {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background: #fdfcff;
  color: black;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.user-creation-input input:focus {
  background: #e0e0e0;
}

.user-creation-button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.user-creation-button:hover {
  background: #0056b3;
}
